<template>
    <div class="page">
        <el-form size="small" :inline="true" ref="searchForm" class="query-form" :model="searchForm" @keyup.enter.native="refreshList(1)">
            <el-form-item prop="name" label="角色名称：">
                <el-input size="small" v-model="searchForm.name" placeholder="请输入角色名称" maxlength="100" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="refreshList(1)" size="small" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
            <el-form-item class="f_r">
                <el-button v-if="hasPermission('sys:role:add')" type="primary" size="small" icon="el-icon-plus"
                           @click="addEdit('add', '')">新增
                </el-button>
                <el-button v-if="hasPermission('sys:role:del')" type="danger" size="small" icon="el-icon-delete"
                           @click="del()">批量删除
                </el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white">
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 298px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column
                        type="selection"
                        width="50">
                </el-table-column>
                <el-table-column
                        prop="name"
                        label="角色名称">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" v-if="hasPermission('sys:role:edit')"
                                 @click="addEdit('edit', scope.row.id)">{{scope.row.name}}
                        </el-link>
                        <el-link type="primary" :underline="false" v-else-if="hasPermission('sys:role:view')"
                                 @click="addEdit('view', scope.row.id)">{{scope.row.name}}
                        </el-link>
                        <span v-else>{{scope.row.name}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        show-overflow-tooltip
                        prop="remarks"
                        label="备注">
                </el-table-column>
                <el-table-column
                        prop="useable"
                        label="是否可用">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.useable == '0' ? 'success' : 'danger'">
                            {{scope.row.useable == '0' ? '是' : '否'}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="420"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('sys:role:edit')" type="text" size="mini"
                                   @click="addEdit('edit', scope.row.id)">修改
                        </el-button>
                        <el-button v-if="hasPermission('sys:role:del')" type="text" size="mini"
                                   @click="del(scope.row.id)">删除
                        </el-button>
                        <el-button v-if="hasPermission('sys:role:jurisdiction')" type="text"
                                   size="mini" @click="showAuth(scope.row)">功能权限
                        </el-button>
                        <el-button v-if="hasPermission('sys:role:data')" type="text" size="mini"
                                   @click="dataAuthor(scope.row)">数据权限
                        </el-button>
                        <el-button v-if="hasPermission('sys:role:assign')" type="text"
                                   size="mini" @click="showRight(scope.row)">分配用户
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background=""
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
            <RoleUserList :role-user-title="roleUserTitle" :role-id="roleId" ref="roleUserList"></RoleUserList>
            <AuthForm ref="authForm"></AuthForm>
            <DataForm ref="dataForm"></DataForm>

            <!-- 弹窗, 新增 / 修改 -->
            <RoleForm ref="roleForm" @refreshDataList="refreshList"></RoleForm>
        </div>
    </div>
</template>
<script>
    import RoleForm from './RoleForm'
    import AuthForm from './AuthForm'
    import RoleUserList from './RoleUserList'
    import DataForm from './DataForm'

    export default {
        data() {
            return {
                searchForm: {
                    name: '',
                    current: 1,
                    size: 10,
                },
                dataList: [],
                total: 0,
                dataListSelections: [],
                roleUserTitle: '',
                roleId: '',
                loading: false
            }
        },
        components: {
            RoleForm,
            AuthForm,
            RoleUserList,
            DataForm
        },
        activated() {
            this.refreshList(1)
        },
        methods: {
            // 获取数据列表
            refreshList(type) {
                if(type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                this.$axios(this.api.user.sysroleList, this.searchForm, 'get').then(data => {
                    if (data.status) {
                        this.dataList = data.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.refreshList()
                        }
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.refreshList()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },

            // 排序
            sortChangeHandle(obj) {
                if (obj.order === 'ascending') {
                    this.orderBy = obj.prop + ' asc'
                } else if (obj.order === 'descending') {
                    this.orderBy = obj.prop + ' desc'
                } else {
                    this.orderBy = ''
                }
                this.refreshList()
            },
            // 新增，修改，查看
            addEdit(method, id) {
                this.$refs.roleForm.init(method, id)
            },
            // 删除
            del(id) {
                if (!id && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.user.sysroleRemoveById, {'ids': ids}, 'delete').then(data => {
                        this.loading = false
                        if (data.status) {
                            this.$message.success('删除成功')
                            this.refreshList()
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            },
            // 重置
            resetSearch() {
                this.$refs.searchForm.resetFields()
                this.currentChangeHandle(1)
            },
            // 分配用户
            showRight(row) {
                this.roleId = row.id
                this.roleUserTitle = row.name
                this.$nextTick(() => {
                    this.$refs.roleUserList.init()
                })
            },
            // 角色授权
            showAuth(row) {
                this.roleId = row.id
                this.$nextTick(() => {
                    this.$refs.authForm.init(row.id)
                    this.roleUserTitle = row.name
                })
            },
            // 数据权限
            dataAuthor(row) {
                this.roleId = row.id
                this.$nextTick(() => {
                    this.$refs.dataForm.init(row.id)
                    this.roleUserTitle = row.name
                })
            },
        }
    }
</script>
